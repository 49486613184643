.__video-dialog {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  
  .video-dialog-backdrop {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .video-dialog-content {
    position: relative;
    width: 90%;
    width: calc(100% - 120px);
    max-width: 1200px;
    aspect-ratio: 16/9;

    .plyr {
      aspect-ratio: 16/9;
    }
    
    .video-dialog-close {
      position: absolute;
      right: -45px;
      top: -36px;
      width: 40px;
      height: 40px;
      background-color: rgba(255,255,255,.85);
      border-radius: 999px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: box-shadow .3s;
    }

    @media(max-width: 1023px) {
      width: calc(100% - 60px);

      .video-dialog-close {
        right: -26px;
        top: -20px;
        width: 24px;
        height: 24px;
      }
    }
  }
}
