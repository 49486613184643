#card-slider {
  user-select: none;

  .swiper-pagination {
    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      border-radius: 99px;
      opacity: 1;
      background-color: rgba(255, 255, 255, .2);
      box-shadow: 0 0 2px rgba(0,0,0,.3);
      
      &.swiper-pagination-bullet-active {
        background-color: rgba(255, 255, 255, .95);
      }
    }
  }

  .swiper-button-next, .swiper-button-prev {
    width: 36px;
    height: 64px;
    @media(max-width: 767px) {
      width: 30px;
      height: 48px;
    }
    background-color: rgba(0,0,0,.3);
    color: var(--white);
    &:after {
      font-size: 16px;
    }
  }
  .swiper-button-next {
    right: 0;
  }
  .swiper-button-prev {
    left: 0;
  }
}
