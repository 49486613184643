.why-doping {
  position: relative;
  background-color: rgba(var(--cornflower-blue-value), .05);
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 430px;
    max-height: 90%;
    width: 100%;
    z-index: 0;
    background-image: url(/assets/images/home-pattern.webp);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: contain;
    opacity: .6;
  }

  .icon-container {
    position: relative;
    z-index: 1;

    .title {
      h2 {
        color: var(--lava);
      }
    }

    .show-more {
      color: var(--lava);
    }
  
    .banner-list {
      .item {
        transform: scale(1);
        opacity: 1;
        height: 100%;
        transition: all .2s;
        
        &.hide {
          @media(max-width: 1023px) {
            padding: 0 !important;
            overflow: hidden;
            height: 0;
            transform: scale(0);
            opacity: 0;
            pointer-events: none;
          }
        }

        .item-content {
          &.unclickable {
            cursor: default !important;
          }

          .item-icon {
            background-color: rgba(var(--navy-blue-value), .8);
            transition: box-shadow .3s, background-color .3s;
          }
          .item-text {
            line-height: 1.4;
          }
          &:not(.unclickable):hover {
            .item-icon {
              background-color: var(--navy-blue);
              box-shadow: 0 0 14px var(--navy-blue);
            }
          }
        }
      }
    }
  }
}
