.video-selector-button {
  &.active {
    background-color: var(--honolulu-blue);
    color: var(--white);
  }
}

.videos {
  .video {
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    overflow: hidden;
    z-index: 0;
    transition: all 0.4s;
    pointer-events: none;

    &.active {
      position: relative;
      z-index: 1;
      opacity: 1;
      height: auto;
      pointer-events: all;
    }
  }
}